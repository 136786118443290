"use client";

import React from "react";
import dynamic from "next/dynamic";
import Script from "next/script";
const App = dynamic(() => import("../App"), { ssr: false });

// eslint-disable-next-line import/no-default-export
export default function LegacyPages() {
  return (
    <OnlyHydrateOnClient>
      <App />
    </OnlyHydrateOnClient>
  );
}

/**
 * We can't use react-router while also using server-side rendering.
 *
 * This is the Next.JS recommended hack around this, to do one render with
 * nothing and a second render which contains the content you care about.
 */
function OnlyHydrateOnClient(props: { children: React.ReactElement }) {
  const [isClient, setIsClient] = React.useState(false);
  React.useEffect(() => {
    setIsClient(true);
  }, []);

  return !isClient ? null : (
    <>
      <Script
        strategy="lazyOnload"
        src="https://www.googletagmanager.com/gtag/js?id=GTM-K5H8QBC"
      />
      <Script id="google-analytics" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', 'GTM-K5H8QBC');
        `}
      </Script>
      {window.location.hostname.startsWith("console.anjuna.io") ||
      window.localStorage.getItem("loadHeapInDev") ? (
        <Script
          strategy="lazyOnload"
          id="Heap"
        >{`(window.heap = window.heap || []),
  (heap.load = function (e, t) {
    (window.heap.appid = e), (window.heap.config = t = t || {});
    var r = document.createElement("script");
    (r.type = "text/javascript"),
      (r.async = !0),
      (r.onerror = () => (window.analyticsDisabled = true)), // We can use this in the React app to know when heap cannot run
      (r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js");
    var a = document.getElementsByTagName("script")[0];
    a.parentNode.insertBefore(r, a);
    for (
      var n = function (e) {
          return function () {
            heap.push(
              [e].concat(Array.prototype.slice.call(arguments, 0))
            );
          };
        },
        p = [
          "addEventProperties",
          "addUserProperties",
          "clearEventProperties",
          "identify",
          "resetIdentity",
          "removeEventProperty",
          "setEventProperties",
          "track",
          "unsetEventProperty",
        ],
        o = 0;
      o < p.length;
      o++
    )
      heap[p[o]] = n(p[o]);
  });
heap.load("999521832");
`}</Script>
      ) : null}
      {props.children}
    </>
  );
}
